@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

:root {
  --accents-1: #fafafa;
  --accents-2: #eaeaea;
  --accents-dark-1: #555;
  --accents-dark-2: #777;
}

@layer components {
  .progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: var(--red);
    transform-origin: 0%;
  }
  .focus-ring-input {
    @apply focus:border-indigo-500 focus:outline-none focus:ring-indigo-500;
  }

  .btn {
    @apply focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2;
  }

  .focus-ring {
    @apply rounded-sm transition duration-75 ease-in-out focus:border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-75 focus:ring-offset-2;
  }

  .focus-within-ring {
    @apply rounded-sm transition duration-75 ease-in-out focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-opacity-75 focus-within:ring-offset-2;
  }
}
@layer utilities {
  .skeleton {
    background-image: linear-gradient(
      270deg,
      var(--accents-1),
      var(--accents-2),
      var(--accents-2),
      var(--accents-1)
    );
    background-size: 400% 100%;
    animation: loading 8s ease-in-out infinite;
  }

  .comment-grid {
    grid-template-columns: fit-content(1.75rem) minmax(0, 1fr);
  }

  @layer dark {
    .skeleton-dark {
      background-image: linear-gradient(
        270deg,
        var(--accents-dark-1),
        var(--accents-dark-2),
        var(--accents-dark-2),
        var(--accents-dark-1)
      );
      background-size: 400% 100%;
      animation: loading 8s ease-in-out infinite;
    }
  }
}
